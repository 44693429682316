<template>
    <v-dialog
        persistent
        v-model="viewDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="400px"
        min-width="400px"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.bookValueDetail') }}</v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loadingDetail"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular color="primary" indeterminate size="40" width="6"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-layout row pt-5>
                    <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyingPrice') + (originalCurrency ? ' (OC)' : ' (LC)') }}</v-flex>
                    <v-flex xs7 lg7 md6 py-1 class="text-end font-weight-bold">{{ formatThisNumber(this.buyingPrice, currencyFormatPrecision) }}</v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.importDuty') + (originalCurrency ? ' (OC)' : ' (LC)') }}</v-flex>
                    <v-flex xs7 lg7 md6 py-1 class="text-end font-weight-bold">{{ formatThisNumber(this.importDuty, currencyFormatPrecision) }}</v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.freight') + (originalCurrency ? ' (OC)' : ' (LC)') }}</v-flex>
                    <v-flex xs7 lg7 md6 py-1 class="text-end font-weight-bold">{{ formatThisNumber(this.freight, currencyFormatPrecision) }}</v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.warehouseCost') + (originalCurrency ? ' (OC)' : ' (LC)') }}</v-flex>
                    <v-flex xs7 lg7 md6 py-1 class="text-end font-weight-bold">{{ formatThisNumber(this.warehouseCost, currencyFormatPrecision) }}</v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue') + (originalCurrency ? ' (OC)' : ' (LC)') }}</v-flex>
                    <v-flex xs7 lg7 md6 py-1 class="text-end font-weight-bold">{{ formatThisNumber(this.bookValue, currencyFormatPrecision) }}</v-flex>
                </v-layout>
                <div class="mt-2 ml-1">
                    <v-icon small color="orange lighten-1" v-if="parseInt(this.reducedValue) === 2">fiber_manual_record</v-icon>
                    <v-icon small color="red darken-1" v-if="parseInt(this.reducedValue) === 3">fiber_manual_record</v-icon>
                </div>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-btn color="default" small @click="viewDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {numberFormat} from "Helpers/helpers";
import {mapGetters} from "vuex";
import {api} from "Api";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

export default {
    name: "LocalSalesItemCostDetail",
    props: ['dialog','localSaleId','localSaleItemId','updateMode'],
    data() {
        return {
            bookValue: null,
            buyingPrice: null,
            freight: null,
            importDuty: null,
            view_dialog: false,
            warehouseCost: null,
            loadingDetail: null,
            reducedValue: null,
            originalCurrency: true
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('localsalesinvoice', {
            LocalSalesInvoice__currency_id: 'LocalSalesInvoice.currency_id'
        },'statePrefix'),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        viewDialog: {
            get() {
                return this.view_dialog;
            },
            set(value){
                this.view_dialog = value;
                if(value === false) {
                    this.$emit('dialog-closed');
                }
            }
        },
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => parseInt(currency.Currency.id) === parseInt(this.LocalSalesInvoice__currency_id))?.Currency?.formatPrecision
        },
    },
    methods: {
        formatThisNumber (value,format) {
            return numberFormat(value,format);
        },
        getCostDetail()
        {
            this.loadingDetail = true;
            api
                .get('/localsales/' + this.localSaleId + '/salesitem-costing/' + this.localSaleItemId)
                .then((response) => {
                    if(response.data.status === 'success'){
                        const detail = response.data.data
                        if(typeof detail === 'object'){
                            this.buyingPrice = detail.buyingPrice;
                            this.importDuty = detail.importDuty;
                            this.freight = detail.freight;
                            this.warehouseCost = detail.warehouseCost;
                            this.bookValue = detail.bookValue;
                            this.reducedValue = detail.reducedValue;
                            this.originalCurrency = detail.originalCurrency;
                            this.loadingDetail = false;
                        } else {
                            this.$toast.error(this.$t('message.errors.detailCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loadingDetail = false;
                        }
                    } else {
                        this.$toast.error(this.$t('message.errors.detailCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loadingDetail = false;
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.detailCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loadingDetail = false;
                })
        }
    },
    watch: {
        dialog(value) {
            this.bookValue = null;
            this.buyingPrice = null;
            this.freight = null;
            this.importDuty = null;
            this.warehouseCost = null;
            this.reducedValue = null;
            this.view_dialog = value;
            this.originalCurrency = true;
            if(value) this.getCostDetail()
        }
    }
}
</script>

<style scoped>

</style>